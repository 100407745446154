import * as React from 'react';

import { Footer, GoogleNoIndex, NavMenu, PageTitle } from '../components/shared';

import { Container } from 'react-bootstrap';
import { SignUpIssueForm } from '../components/SignUpIssueForm';

const SignIssueQuestionPage: React.FunctionComponent = () => {
  return (
    <>
      <PageTitle />
      <GoogleNoIndex />
      <NavMenu solidColor notFixed />
      <Container fluid style={{ maxWidth: 800, margin: '40px auto' }}>
        <h1>Sign Up Issue or Question</h1>
        <SignUpIssueForm />
        <div className="clearfix"></div>
      </Container>
      <Footer />
    </>
  );
};

export default SignIssueQuestionPage;
