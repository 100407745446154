import * as Yup from 'yup';

import { Button, Form } from 'react-bootstrap';
import { Field, Formik } from 'formik';

import React from 'react';
import classNames from 'classnames';
import { getApiClient } from '../utils';

export const SignUpIssueForm: React.FunctionComponent = () => {
  const [disableForm, setDisableForm] = React.useState(false);

  const ContactLeadSchema = Yup.object().shape({
    issueType: Yup.string().required('Issue Type required.'),
    firstName: Yup.string().required('First Name required.'),
    lastName: Yup.string().required('Last Name required.'),
    email: Yup.string().email('Email is invalid.').required('Email required.'),
    phone: Yup.string()
      .matches(/^\((\d{3})\) (\d{3})-(\d{4})$/, 'Phone is invalid.')
      .required('Phone required.'),
    address: Yup.string().required('Address required.'),
    county: Yup.string().required('County required.'),
  });

  const formatPhone = (phoneNumber: string) => {
    let cleaned = ('' + phoneNumber).replace(/\D/g, '');
    cleaned = cleaned.substring(0, 10);
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match && cleaned.length === 10) {
      return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } else {
      return cleaned;
    }
  };

  return (
    <div id="issue-form">
      <Formik
        initialValues={{ issueType: '', firstName: '', lastName: '', email: '', phone: '', address: '', county: '', comments: '', site: 'TTP' }}
        validationSchema={ContactLeadSchema}
        onSubmit={(values) => {
          setDisableForm(true);
          getApiClient()
            .post<any>('/api/issue-form', values)
            .then((response) => {
              document.location.href = '/message-received';
            })
            .catch((error) => {
              console.log(error);
              setDisableForm(false);
            });
        }}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <Form.Group className="mt-3 mb-3">
              <Form.Label className="mb-2">How can we assist you?*</Form.Label>
              <br />
              <label className={classNames('d-block ps-3', { 'text-danger': (formik.errors.issueType && formik.touched.issueType) as boolean })}>
                <Field type="radio" name="issueType" value="I would like help with more than 5 properties" /> I would like help with more than 5 properties
              </label>
              <label className={classNames('d-block ps-3', { 'text-danger': (formik.errors.issueType && formik.touched.issueType) as boolean })}>
                <Field type="radio" name="issueType" value="My county is not listed" /> My county is not listed
              </label>
              <label className={classNames('d-block ps-3', { 'text-danger': (formik.errors.issueType && formik.touched.issueType) as boolean })}>
                <Field type="radio" name="issueType" value="I don't see my property or my name is not listed as the owner." /> I don't see my property or my
                name is not listed as the owner.
              </label>
              <label className={classNames('d-block ps-3', { 'text-danger': (formik.errors.issueType && formik.touched.issueType) as boolean })}>
                <Field type="radio" name="issueType" value="I have a question or would like to speak to a team member." /> I have a question or would like to
                speak to a team member.
              </label>
            </Form.Group>

            <div className="row">
              <div>Name*</div>
              <Form.Group className="col">
                <Form.Control
                  id="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={(formik.errors.firstName && formik.touched.firstName) as boolean}
                  disabled={disableForm}
                />
                <Form.Text className="text-dark">First Name</Form.Text>
              </Form.Group>

              <Form.Group className="col">
                <Form.Control
                  id="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={(formik.errors.lastName && formik.touched.lastName) as boolean}
                  disabled={disableForm}
                />
                <Form.Text className="text-dark">Last Name</Form.Text>
              </Form.Group>
            </div>

            <Form.Group className="mt-2">
              <Form.Label className="mb-0">Email*</Form.Label>
              <Form.Control
                id="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={(formik.errors.email && formik.touched.email) as boolean}
                disabled={disableForm}
              />
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Label className="mb-0">Phone*</Form.Label>
              <Form.Control
                id="phone"
                value={formik.values.phone}
                onChange={(e) => {
                  e.currentTarget.value = formatPhone(e.currentTarget.value);
                  formik.handleChange(e);
                }}
                onBlur={(e) => {
                  e.currentTarget.value = formatPhone(e.currentTarget.value);
                  formik.handleBlur(e);
                }}
                isInvalid={(formik.errors.phone && formik.touched.phone) as boolean}
                disabled={disableForm}
              />
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Label className="mb-0">Address*</Form.Label>
              <Form.Control
                id="address"
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={(formik.errors.address && formik.touched.address) as boolean}
                disabled={disableForm}
              />
            </Form.Group>

            <Form.Group className="mt-2">
              <Form.Label className="mb-0">County*</Form.Label>
              <Form.Control
                id="county"
                value={formik.values.county}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={(formik.errors.county && formik.touched.county) as boolean}
                disabled={disableForm}
              />
            </Form.Group>

            <Form.Group className="mt-3">
              <Form.Label className="mb-0">Comments (optional)</Form.Label>
              <Form.Control
                id="comments"
                as="textarea"
                rows={5}
                value={formik.values.comments}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={(formik.errors.comments && formik.touched.comments) as boolean}
                disabled={disableForm}
              />
            </Form.Group>

            {Object.values(formik.errors).length > 0 && formik.submitCount > 0 && (
              <div style={{ border: '2px solid red', color: 'red', padding: '5px 15px', margin: '16px 0' }}>
                {Object.values(formik.errors).map((error) => (
                  <div>{error}</div>
                ))}
              </div>
            )}

            <div className="d-flex align-items-center mt-3">
              <Button type="submit" size="lg" className="text-uppercase m-auto" disabled={disableForm}>
                Submit Form
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
